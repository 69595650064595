import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { AuthGuard } from './auth-guard/auth.guard';
import { OtpComponent } from './otp/otp.component';
import { OrganisationComponent } from './Organizations/organisation/organisation.component';
import { ApplicationComponent } from './Applications/list/application/application.component';
import { CreateOrganisationComponent } from './Organizations/edit/create-organisation/create-organisation.component';
import { UsersComponent } from './Users/list/users/users.component';
// import { PartnersComponent } from "./Partners/list/partners/partners.component";
import { InternalUsersComponent } from './InternalUsers/list/internalUsers.component';
import { ManageRolesComponent } from './manage-roles/manage-roles.component';
import { ForgotPasswordComponent } from './forgot-password/forgot-password.component';
import { GeneratePasswordComponent } from './generate-password/generate-password.component';
import { AddApplicationComponent } from './Applications/add_edit/add-application/add-application.component';
import { AdminSettingsComponent } from './admin-settings/admin-settings.component';
import { AddOrganisationComponent } from './Organizations/add/add-organisation/add-organisation.component';
import { SegmentComponent } from './Segments/segment/segment.component';
import { SegmentListComponent } from './Segments/segment-list/segment-list.component';
import { CreateUserComponent } from './Users/add_edit/create-user/create-user.component';
import { ApiandservicesComponent } from './APIAndServices/apiandservices/apiandservices.component';
import { GenerateCredentialsComponent } from './APIAndServices/generate-credentials/generate-credentials.component';
import { ActivityComponent } from './activity/activity.component';
// import { DomainComponent } from './domain/domain.component';
import { SsoComponent } from './sso/sso/sso.component';
import { ThemesComponent } from './themes/themes.component';
import { ThemeViewComponent } from './themes/theme-view/theme-view.component';
import { ImportUserComponent } from './Users/import/import-user/import-user.component';
import { ApiConnectorComponent } from './API Connector/api-connector/api-connector.component';
import { CreateApiConnectorComponent } from './API Connector/create-api-connector/create-api-connector.component';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { UserProvisioningComponent } from './user-provisioning/user-provisioning.component';
import { TasksComponent } from './Tasks/tasks/tasks.component';
import { ManageContentComponent } from './manage-content/manage-content.component';
import { ChangePasswordComponent } from './change-password/change-password.component';
import { SsoListComponent } from './sso/sso-list/sso-list.component';
import { LoaderComponent } from './loader/loader.component';
import { CustomzationCentreComponent } from './customzation-centre/customzation-centre.component';
import { PartnersComponent } from './partners/partners.component';
import { CreatePartnerComponent } from './partners/create-partner/create-partner.component';
import { CreateInternalUserComponent } from './InternalUsers/create-internal-user/create-internal-user.component';
import { UsersListMappingComponent } from './InternalUsers/listWithMapping/users-list-mapping/users-list-mapping.component';
import { ManageKeywordsComponent } from './manage-keywords/manage-keywords.component';
import { ManageSegmentsComponent } from './manage-segments/manage-segments.component';
import { FindReplaceComponent } from './find-replace/find-replace.component';
import { CreateSegmentComponent } from './manage-segments/create-segment/create-segment.component';
import { GuidesComponent } from './guides/guides.component';
import { PlayerPackagesComponent } from './player-packages/player-packages.component';
import { ImportSegmentValueComponent } from './Segments/segment-list/import-segment-value/import-segment-value.component';
import { ExportGuidesComponent } from './export/export-guides/export-guides.component';
import { ExportUsersComponent } from './export/export-users/export-users.component';
import { GuideInventoryComponent } from './guide-inventory/guide-inventory.component';
import { FeatureFlagsComponent } from './feature-flags/feature-flags.component';
import { MaintenanceModeComponent } from './maintenance-mode/maintenance-mode.component';
import { ProductUsageComponent } from './product-usage/product-usage.component';
import { GuideDetailComponent } from './guide-inventory/guide-detail/guide-detail.component';

const routes: Routes = [
  {
    path: 'login',
    component: LoginComponent,
    data: { roles: ["superadmin", "partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path: 'organization/:org_id',
    component: DashboardComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path: 'otp',
    component: OtpComponent,
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path: 'user/unlock/:token',
    component: OtpComponent,
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path: 'organization/:org_id/application',
    component: ApplicationComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },
  {
    path: 'edit_organization/:org_id',
    component: CreateOrganisationComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin","app_admin","creator"] }
  },
  {
    path: 'organization/:org_id/get_secret_key',
    component: ApiandservicesComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"], 
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/generate_secret_key',
    component: GenerateCredentialsComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"],
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
    },
    {
        path: 'organization/:org_id/edit_secret_key/:key_id',
        component: GenerateCredentialsComponent,
        canActivate: [AuthGuard],
        data: { roles: ["partner_admin", "owner", "mg_admin"] }
    },
  {
    path: 'add-organization',
    component: AddOrganisationComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "mg_admin"] }
  },
  {
    path: 'organization/:org_id/users',
    component: UsersComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "mg_admin", "owner", ] }
  },
  // {
  //   path: 'users',
  //   component: UsersComponent,
  //   canActivate: [AuthGuard],
  //   data: { roles: ["superadmin","partner_admin", "mg_admin"] }
  // },  
  {
    path: 'users',
    component: InternalUsersComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin",] }
  }, 
  {
    path: 'partners',
    component: PartnersComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin"] }
  },
  {
    path: 'organization/:org_id/roles',
    component: ManageRolesComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'forgotpassword',
    component: ForgotPasswordComponent,
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path: 'password/reset',
    component: GeneratePasswordComponent,
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path:'organization/:org_id/change_password/:user_id',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path:'organization/:org_id/change_user_password/:user_id',
    component: ChangePasswordComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/application/add',
    component: AddApplicationComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] },
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/application/edit/:app_id',
    component: AddApplicationComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin"] },
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },
  
  {
    path: 'organization/:org_id/application/assign_app/:key_id',
    component: ApplicationComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin"] },
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/admin-settings',
    component: AdminSettingsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "mg_admin"] }
  },
  {
    path: 'organization/:org_id/segmentation',
    component: SegmentComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'segment-list/:org_id/:segment_id',
    component: SegmentListComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'segment-list/:org_id/:segment_id/import',
    component: ImportSegmentValueComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
    },
    {
        path: 'segment/:org_id/import',
        component: ImportSegmentValueComponent,
        canActivate: [AuthGuard],
        data: { roles: ["partner_admin", "owner", "mg_admin"] }
    },
  {
    path: 'organization/:org_id/users/add',
    component: CreateUserComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/users/import',
    component: ImportUserComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/users/edit/:user_id',
    component: CreateUserComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/activity',
    component: ActivityComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  // {
  //   path: 'organization/:org_id/domain_list',
  //   component: DomainComponent,
  //   canActivate: [AuthGuard],
  //   data: { roles: ["superadmin", "owner"] }
  // },
  {
    path: 'organization/:org_id/sso',
    component: SsoListComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin", "partner_admin", "mg_admin"] }
  },
  {
    path: 'organization/:org_id/sso/:sso_id',
    component: SsoComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin", "partner_admin", "mg_admin"] }
  },
  {
    path: 'organization/:org_id/api_connector',
    component: ApiConnectorComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/api_connector/add',
    component: CreateApiConnectorComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/api_connector/edit/:connector_id',
    component: CreateApiConnectorComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/themes',
    component: ThemesComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/themes/:theme_id',
    component: ThemeViewComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'organization/:org_id/provision',
    component: UserProvisioningComponent,
    canActivate: [AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }

  },
  {
    path:'organization/:org_id/export_guides',
    component:ExportGuidesComponent,
    canActivate:[AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", 'app_admin'] }
    data: { roles: ["partner_admin", "owner",  "mg_admin", 'app_admin'] }

 },
 
 {
    path:'organization/:org_id/export_users',
    component:ExportUsersComponent,
    canActivate:[AuthGuard],
    // data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", 'app_admin'] }
    data: { roles: ["partner_admin", "owner",  "mg_admin", 'app_admin'] }

 },
  {
     path:'organization/:org_id/tasks_list',
     component:TasksComponent,
     canActivate:[AuthGuard],
    //  data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
     data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
     path:'organization/:org_id/tasks_list/:application_id/:task_list_id/manage_content',
     component:ManageContentComponent,
     canActivate:[AuthGuard],
    //  data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin"] }
     data: { roles: ["partner_admin", "owner",  "mg_admin"] }

  },
  {
    path: 'loader',
    component: LoaderComponent,
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
  {
    path: 'organization/:org_id/customization',
    component: CustomzationCentreComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] } //added partner admin and removed super_Admin access
  },
  {
    path: 'partners/add',
    component: CreatePartnerComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin"] }
  },
  {
    path: 'partners/edit/:partner_id',
    component: CreatePartnerComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin"] }
  },
  {
    path: 'users/add/internal',
    component: CreateInternalUserComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin"] }
  },
  {
    path: 'users/org/assignment/:user_id/:partner_id',
    component: UsersListMappingComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin","partner_admin"] }
  },
  {
    path: 'organization/:org_id/manage_keywords',
    component: ManageKeywordsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },
  {
    path: 'organization/:org_id/manage_segments',
    component: ManageSegmentsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },
    {
    path: 'organization/:org_id/find_replace',
    component: FindReplaceComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },
  {
    path: 'segments/add',
    component: CreateSegmentComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },
  {
    path: 'segments/edit/:segment_id',
    component: CreateSegmentComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },
  {
    path: 'organization/:org_id/guides',
    component: GuidesComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin"] }
  },  
  {
    path: 'organization/:org_id/guide_explorer',
    component: GuideInventoryComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin", "creator"] }
  },  
  {
    path: 'organization/:org_id/guide_explorer/:category_id/:guide_id/guide_detail',
    component: GuideDetailComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin", "app_admin", "creator"] }
  },  
  {
    path: 'organization/:org_id/player_packages',
    component: PlayerPackagesComponent,
    canActivate: [AuthGuard],
    data: { roles: ["partner_admin", "owner",  "mg_admin"] }
  },
  {
    path: 'feature_flags',
    component: FeatureFlagsComponent,
    canActivate: [AuthGuard],
    data: { roles: ["superadmin"] }
    },
    {
        path: 'maintenance_mode',
        component: MaintenanceModeComponent,
        canActivate: [AuthGuard],
        data: { roles: ["partner_admin", "owner", "mg_admin", "app_admin", "superadmin","creator", "user"] }
    },
    {
        path: 'platform_usage',
        component: ProductUsageComponent,
        canActivate: [AuthGuard],
        data: { roles: [ "superadmin"] }
    },
  {
    path: "**",
    component: PageNotFoundComponent,
    data: { roles: ["superadmin","partner_admin", "owner",  "mg_admin", "app_admin", "creator", "user"] }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
