<div class="d-flex">
    <app-spinner *ngIf="!loader"></app-spinner>
    <div class="container-fluid content-container p-3 toggle-view">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">

            <div class="d-flex mb-3 flex-column">
                <div class="col-sm-9 col-md-9 col-lg-9 col-xl-9 px-4 pt-4 pb-2 sectionHeader">
                    {{this.admin_data.platFormUsageReport}}<br>
                    <p class="font14 mt-3">{{this.admin_data.platformUsageReportInfo}}</p>
                </div>
                <div>
                    <div class="sectionBody">
                        <div class="d-flex flex-row mx-3 mb-3 mt-0 justify-content-around">
                            <div class="col-lg-4 px-0">
                                <!-- <select name="month" id="month" class="form-control" (change)="onMonthChanged($event)">
                                    <option *ngFor="let month of Months" [value]="month">{{month}}</option>
                                </select> -->

                                <app-custom-select [dataList]="Months"
                                    (updateDataList)="onMonthChanged($event)" [selected]="selectedMonth"
                                    componentName="default"></app-custom-select>
                            </div>

                            <div class="col-lg-6 ">
                                <app-multi-checkbox-dropdown className="width-100-p" className2="width-100-p"
                                    componentName="productUsage" [appList]="dropdownList"
                                    [placeholder]="this.admin_data.organisationSelectText"
                                    (updateAppList)="updateAppList($event)" searchAllowed="true"
                                    isSingleSelect="true"></app-multi-checkbox-dropdown>
                            </div>

                            <div class="pr-0">
                                <button [disabled]="this.selectedItems.length == 0" class="btn btn-primary" (click)="downloadReport()">{{this.admin_data.downloadReport}}</button>
                            </div>
                            <!-- <ng-multiselect-dropdown class="col-lg-8 font14 organisation-dropdown"
                                [placeholder]="'Select Organisation'" [data]="dropdownList"
                                (onSelect)="onItemSelect($event)" [(ngModel)]="selectedItems"
                                [settings]="dropdownSettings">
                                {{this.admin_data.generateCredentialsVariable15}}
                            </ng-multiselect-dropdown> -->
                        </div>
                        <div class="mx-2">
                            <div class="d-flex flex-row">
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.totalUsers}}</span>
                                    <span>{{this.stats.totalUsers}}</span>
                                </div>
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.newUsers}}</span>
                                    <span>{{this.stats.newUsers}}</span>
                                </div>
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.activeUserSessions}}</span>
                                    <span>{{this.stats.activeUserSessions}}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-row">
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.guidesCreated}}</span>
                                    <span>{{this.stats.guidesCreated}}</span>
                                </div>
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.guidesPublished}}</span>
                                    <span>{{this.stats.guidesPublished}}</span>
                                </div>
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.guidesPlays}}</span>
                                    <span>{{this.stats.guidesPlays}}</span>
                                </div>
                            </div>
                            <div class="d-flex flex-row">
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.tooltipsCreated}}</span>
                                    <span>{{this.stats.tooltipsCreated}}</span>
                                </div>
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.tooltipsPublished}}</span>
                                    <span>{{this.stats.tooltipsPublished}}</span>
                                </div>
                                <div class="card p-4 m-2 stat-card align-items-center">
                                    <span>{{this.admin_data.tooltipsPlays}}</span>
                                    <span>{{this.stats.tooltipsPlays}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</div>