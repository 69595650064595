import { Component, OnInit } from '@angular/core';

import adminLabels from "../../../constants/ApplicationStrings/trans_utils";
import { UserService } from 'src/app/common/services/user.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { ApiService } from 'src/app/common/services/api.service';
import { RoleService } from 'src/app/common/services/role.service';
import { Title } from '@angular/platform-browser';
import { filter, takeUntil } from 'rxjs/operators';
import { PaginationService } from 'src/app/pagination.service';
import { Subject } from 'rxjs';
import { PartnerService } from 'src/app/common/services/partner.service';
import { ErrorResponseService } from 'src/app/common/services/error-response.service';
declare var $;
@Component({
    selector: 'app-users-list-mapping',
    templateUrl: './users-list-mapping.component.html',
    styleUrls: ['./users-list-mapping.component.css']
})
export class UsersListMappingComponent implements OnInit {

    user_id = ""
    breadcrumb;
    admin_data = adminLabels;
    search_text = "";
    limit = 10;
    offset = 0;
    orgList = [];
    partner_id = "0";
    col_widths = ['70%', '30%'];
    pageLimitSet = false;
    table_titles = [this.admin_data.tableHeaderTitle, this.admin_data.tableHeaderActions]
    showFilterMenu = false;
    curr_status = "assign";
    partner_filter = "0";
    curr_user_partner = "0"
    customSelectObj = [
        { name: this.admin_data.assigned, value: "assign" },
        { name: this.admin_data.unassigned, value: "unassign" }
    ]
    partnerSelectObject = [];
    private destroy = new Subject<void>();
    constructor(
        public userService: UserService,
        private router: Router,
        private apiService: ApiService,
        private roleService: RoleService,
        private route: ActivatedRoute,
        private titleService: Title,
        public paginationService: PaginationService,
        public partnerService: PartnerService,
        private errorResponseService: ErrorResponseService,
    ) {
        userService.getUserData()
        this.paginationService.initializeValues();
        if (this.userService.partner_id !== "") {
            this.partner_id = this.userService.partner_id
        }
        this.route.paramMap.subscribe((params) => {
            if (params['params']['user_id']) {
                this.user_id = params['params']['user_id']
                this.partner_filter = params['params']['partner_id'] + ""
                this.curr_user_partner = this.partner_filter
                this.router.events
                    .pipe(filter((event) => event instanceof NavigationEnd), takeUntil(this.destroy))
                    .subscribe(() => {
                        let breadCrumb = [
                            {
                                name: this.admin_data.usersBreadCrumbTitle,
                                route: ['/users']
                            },
                            {
                                name: this.admin_data.orgAssign,
                                route: ['/users/org/assignment', this.user_id]
                            }
                        ];
                        if (this.userService.organization_id === "000") {
                            let tempDashboardBreadCrumb = this.userService.getDashboardBreadCrumb();
                            breadCrumb.unshift(tempDashboardBreadCrumb);
                        }
                        this.userService.setBreadcrumb(breadCrumb)
                        this.breadcrumb = this.userService.breadCrumbs;
                    }
                    );

            }
        });
        this.getOrgList();
        this.populatePartnerArray();
    }

    ngOnInit() {
       
    }

    ngOnDestroy(){
        this.destroy.next();
        this.destroy.complete();
    }
    searchOrgList() {
        // this.limit = 10;
        this.offset = 0
        this.paginationService.initializeValues();
        this.getOrgList();
    }

    getOrgList() {
        // if (this.curr_status == undefined || this.curr_status == "") {
        //     this.userService.getOrgList(this.limit, this.offset, this.user_id, this.partner_id, this.search_text).subscribe((response) => {
        //         this.orgList = [];
        //         this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
        //         this.pageLimitSet = true;
        //         let array = response["data"]["organizations"]
        //         for (let index = 0; index < array.length; index++) {

        //             let orgData = {
        //                 id: array[index]["organization_id"],
        //                 title: array[index]["name"],
        //                 mappingStatus: array[index]["mapping_status"]
        //             }
        //             this.orgList.push(orgData)
        //         }
        //         this.paginationService.setPaginationStatus(this.orgList, this.limit);
        //         this.close();
        //     })
        // } else {
        this.userService.getOrgListFilteredForMgAdmin(this.curr_status, this.limit, this.offset, this.user_id, this.search_text, this.partner_filter).subscribe((response) => {
            this.orgList = [];
            this.paginationService.calculatePageLimit(response['data']['total_rows'], this.limit);
            this.pageLimitSet = true;
            let array = response["data"]["organizations"]
            let local_mapping_status = "";
            if(this.curr_status === 'assign'){
                local_mapping_status = 'assign'
            } else {
                this.curr_status = 'unassign'
            }
            for (let index = 0; index < array.length; index++) {

                let orgData = {
                    id: array[index]["organization_id"],
                    title: array[index]["name"],
                    mappingStatus: local_mapping_status
                }
                this.orgList.push(orgData)
            }
            this.paginationService.setPaginationStatus(this.orgList, this.limit);
            this.close();
        })
    }


    appendOffsetAndGetUsers() {

        let offset = this.paginationService.appendOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getOrgList()
        }
    }

    setIndex(index) {
        let offset = this.paginationService.setIndex(index, this.limit, this.offset);
        if (offset >= 0) {

            this.offset = offset;
            this.getOrgList();
        }

    }

    prependOffsetAndGetUsers() {
        let offset = this.paginationService.prependOffset(this.limit, this.offset);
        if (offset >= 0) {
            this.offset = offset;
            this.getOrgList()
        }
    }

    changeMappingStatus(pos, org_id, action) {
        let request = {
            organization_id: org_id,
            user_id: this.user_id,
            action: action
        }
        this.userService.assignOrgRoleGlobal(request).subscribe((response) => {
            if (response["error"] === false) {
                this.orgList[pos]["mappingStatus"] = action;
                this.getOrgList();
            } else{
                let errorResponse = this.errorResponseService.getErrorResponseMessage(response['message'][0]);
                this.userService.errorMessage = errorResponse;
                $("#errorModal").modal("show");
            }

        })
    }

    gotoFirstPage() {
        this.setIndex(1);
    }

    gotoLastPage(event) {
        this.setIndex(event);
    }

    changeLimit(event) {
        this.limit = parseInt(event);
        this.offset = 0;
        this.getOrgList()
        this.paginationService.initializeValues();
    }

    gotoPage(page) {
        this.offset = this.paginationService.gotoPage(page, this.limit, this.offset)
        if (this.offset >= 0) this.getOrgList();
    }

    clear() {
        this.curr_status = "";
    }

    applyStatus(status = "") {
        this.curr_status = status;
        this.getOrgList();
    }

    applyPartnerFilter(partnerId){
        this.partner_filter = partnerId;
        this.getOrgList();
    }
    close() {
        this.showFilterMenu = false;
    }

    populatePartnerArray(){
        this.partnerSelectObject = [];
        if (this.partner_id === "0") {
            this.partnerSelectObject.push({
                value: "0",
                name: "SuperAdmin"
            });
            this.partnerService.getPartnerList(200, 0).subscribe((response) => {
                for (let index = 0; index < response["data"]["partners"].length; index++) {
                    let partnerSelect = {
                        value: response["data"]["partners"][index]["id"],
                        name: response["data"]["partners"][index]["title"],
                    }
                    this.partnerSelectObject.push(partnerSelect);
                }
            })
        }
    }
}
