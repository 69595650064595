<div class="d-flex">
    <div class="container-fluid content-container p-3 toggle-view"
        [ngClass]="{'expanded-view':(userService.organization_id != '000' && userService.sidebarOpen),'collapsed-view':(userService.organization_id != '000' && !userService.sidebarOpen)}">
        <app-breadcrumb [breadcrumb]="breadcrumb"></app-breadcrumb>
        <section class="component-section">
            <div class="sectionBody">
                <div class="col-sm-4 col-md-4 col-lg-4 col-xl-4 section-header mb-2 p-0">
                    <span class="section-title">{{this.admin_data.guideInventory}}</span>
                </div>
                <div class="col-sm-8 col-md-8 col-lg-8 col-xl-8 p-0 text-right" style="min-height: 40px;">
                    <app-custom-select [dataList]="languageArray" componentName="default"
										[selected]="selectedLanguage"
										(updateDataList)="setActions($event)" dropdownPosition="inherit"
										[ngStyle]="{ 'position': 'relative' }"></app-custom-select>
                </div>
                <div class="d-flex flex-column">
                    <textarea name="tour-title" id="" [value]="getGuideTitle()"></textarea>
                    <textarea name="tour-desc" id=""></textarea>
                </div>
                <div>
                    <table>
                        <thead>
                            <tr>
                                <th>Step No</th>
                                <th>
                                    Step Title
                                </th>
                                <th>Step Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr *ngFor="let step of guideDetails['steps']; index as i">
                                <td>{{i}}</td>
                                <td>
                                    <textarea name="" id="" [value]="getStepTitle(i)"></textarea>
                                </td>
                                <td>
                                    <textarea name="" id="" [value]="getStepDescription(i)"></textarea>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    </div>
</div>
