<div class="d-flex">
    <div class="container-fluid p-3"
        [ngClass]="{'content-container toggle-view expanded-view': (userService.organization_id != '000')}">
        <ul *ngIf="userService.user.role == 'superadmin' || userService.user.role == 'partner_admin'" class="nav nav-tabs" id="userTabs" role="userTabsList">
            <li class="nav-item" (click)="changeTab('active')">
                <a class="nav-link custom-nav-link active" id="internal-user-tab" data-toggle="tab" 
                    role="tab" aria-controls="internalUsers" aria-selected="true">{{this.admin_data.activeOrgs}}</a>
            </li>
            <li class="nav-item" (click)="changeTab('suspended')">
                <a class="nav-link custom-nav-link" id="external-user-tab" data-toggle="tab" role="tab"
                    aria-controls="externalUsers" aria-selected="true">{{this.admin_data.suspendedDeletedOrgs}}</a>
            </li>
          </ul>
        <div class="container-fluid bg-white px-4 pt-4 pb-0 box-shadow-t1 clearfix">
            <div class="d-flex align-items-center justify-content-between my-3">
                <div class="col-5 col-sm-5 col-md-5 col-lg-5 col-xl-5">
                    <ng-container *ngIf="organisations!=undefined && activeTab== 'active'">
                    <b *ngIf="organisations.length!=0">{{admin_data.organisationSelectText}}</b>
                     </ng-container>
                </div>
                <div class="col-sm-7 col-md-7 col-lg-7 col-xl-7 p-0 text-right">
                    <div class="input-group w-50 mx-2 d-inline-flex">
                        <input id="searchInputOrg" class="form-control py-2 search" type="search" autocomplete="off"
                            [(ngModel)]="searchText" placeholder={{this.admin_data.searchPlaceholder}}
                            (input)="validateInput($event); searchOrganiations($event.keyCode)" (search)="searchOrganiations(13)" aria-label="Search">
                        <!-- <span class="input-group-append">
                            <div class="cursor-pointer input-group-text bg-transparent" (click)="searchOrganiations(13)">
                              <svg-icon src="../assets/svgs/search-icon.svg"></svg-icon>
                            </div>
                        </span> -->
                    </div>
                    <ng-container *ngIf="(userService.user.role == 'superadmin' || userService.user.role == 'partner_admin') && activeTab== 'active'">
                        <button type="button" class="btn btn-primary ml-2" [routerLink]="['/add-organization']">
                            {{admin_data.organisationAddText}}
                        </button>
                    </ng-container>
                </div>
            </div>
            <ng-container *ngIf="activeTab=='active'; else suspendedOrgs">
                <div class="row org-card-wrapper">
                    <ng-container *ngFor="let organisation of organisations;let j=index">
                        <div (mouseover)="showIcons[j]=true" (mouseout)="showIcons[j]=false" class="col-sm-6 col-md-6 col-lg-3 p-3">
                            <div class="card org-card border-top-0" (click)="select(j)">
                                <div class="card-header org-card-header m-0 " [ngStyle]="{'border-color': (organisation['partner_id'] === '0') ? '#b3463f !important' : 'red !important'}"
                                    [ngClass]="{ 'border-red': organisation['partner_id'] !== '0' ,'border-primary': organisation['is_verified']=='1' && organisation['is_suspend']=='0', 'border-warning': (organisation['is_verified']=='1' || organisation['is_suspend']=='0') && !(organisation['is_verified']=='1' && organisation['is_suspend']=='0')}">
                                    <div class="row align-items-center" style="position: relative;">
                                        <div class="orgName height30 alignCenter">
                                            <h5 class="card-title m-0 text-truncate">{{organisation['name']}}</h5>
                                        </div>
                                        <div class=" text-right card-action-btns">
                                            <!-- <ng-container *ngIf="userService.user.role == 'superadmin' || userService.user.role == 'partner_admin'">
                                                <svg-icon src="../../../assets/svgs/org_delete.svg" class="org-action mx-1" data-toggle="modal"
                                                data-target="#exampleModal"
                                                (click)="showModal(j);$event.stopPropagation();">
                                                </svg-icon>
                                            </ng-container> -->
                                            <svg-icon src="../../../assets/svgs/org_edit.svg" class="org-action mx-1" 
                                                (click)="edit(j); $event.stopPropagation()"></svg-icon>
                                                
                                            <svg-icon src="../../../assets/svgs/visibility-icon.svg" class="visibility-icon mx-1" 
                                            (click)="openAssignedAdminsModal(j); $event.stopPropagation()"></svg-icon>
                                            <!-- <svg-icon src="../../../assets/svgs/org_tick.svg" class="org-action mx-1 cursor cursor-default">
                                            </svg-icon> -->
                                        </div>
                                    </div>
                                </div>
                                <div class="card-body org-card-body"
                                    [ngClass]="{'bg-primary': organisation['is_verified']=='1' && organisation['is_suspend']=='0', 'bg-warning': (organisation['is_verified']=='1' || organisation['is_suspend']=='0') && !(organisation['is_verified']=='1' && organisation['is_suspend']=='0')}">
                                    <ul class="list-group list-group-flush flex-row justify-content-between">
                                        <!-- <li class="list-group-item border-0 d-flex align-items-center p-0"
                                            (click)="$event.stopPropagation()">
                                            <label class="mb-0" for="customSwitch1/+{{j}}">{{admin_data.organisationVerifiedText}}</label>
                                            <label class="switch mx-2 mb-0">
                                                <input type="checkbox" class="primary" id="customSwitch1/+{{j}}"
                                                    [checked]="organisation['is_verified']=='1'"
                                                    (click)="toggleCheck(verifyChecked[j])"
                                                    (change)="changeVerify(organisation,$event.target.checked)">
                                                <span class="slider round"></span>
                                            </label>
                                        </li> -->
                                        <ng-container *ngIf="userService.user.role == 'superadmin' || userService.user.role == 'partner_admin'">
                                        <li class="list-group-item border-0 d-flex align-items-center p-0"
                                            (click)="$event.stopPropagation()">
                                            <label class="mb-0" for="customSwitch2/+{{j}}">{{admin_data.organisationActiveText}}</label>
                                            <label class="switch mx-2 mb-0">
                                                <input type="checkbox" class="primary" id="customSwitch2/+{{j}}"
                                                    [checked]="organisation['is_suspend']=='0'"
                                                    (click)="toggleCheck(activeChecked[j])"
                                                    (change)="changeActive(organisation,$event.target.checked)">
                                                    <ng-container *ngIf="organisation['partner_id'] === '0';else redSlider">
                                                <span class="slider round" ></span></ng-container>
                                                <ng-template #redSlider>
                                                    <span class="slider-red round"></span>
                                                </ng-template>
                                            </label>
                                        </li>
                                    </ng-container>
                                    </ul>
                                <div *ngIf="userService.user.role === 'superadmin' || userService.user.role === 'partner_admin' || userService.user.role === 'mg_admin'"  class="d-flex flex-column font14 mt-2">
                                    <div>
                                        <b>{{admin_data.addOrganisationType + " : " }}</b>
                                        {{ organisation['organization_type'] == 'paid' ?
                                        admin_data.organisationPaid : admin_data.organisationTrial}}
                                    </div>
                                    <div>
                                        <b>{{admin_data.productType + " : "}}</b>
                                        {{ organisation['product_type'] === 'legacy' ? admin_data.legacy : admin_data.enterprise}}
                                    </div>
                                    <div *ngIf="userService.user.role === 'superadmin'">
                                        <b>{{admin_data.mirrorApplications + " : "}}</b>
                                        {{ organisation['has_mirror_apps']== '1' ? admin_data.enabled : admin_data.disabled }}
                                    </div>
                                </div>
                                </div>
                                <div class="card-footer org-card-footer border-0 pt-0"
                                    [ngClass]="{'bg-primary': organisation['is_verified']=='1' && organisation['is_suspend']=='0', 'bg-warning': (organisation['is_verified']=='1' || organisation['is_suspend']=='0') && !(organisation['is_verified']=='1' && organisation['is_suspend']=='0')}">
                                    <small class="textEllipsis">{{organisation['about']}}</small>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="organisations!=undefined">
                        <span *ngIf="organisations.length==0" class="m-auto padding50">{{admin_data.noDataFoundText}}</span>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #suspendedOrgs>
                <div class="row org-card-wrapper">
                    <ng-container *ngFor="let organisation of organisations;let j=index">
                        <div class="col-sm-6 col-md-6 col-lg-3 p-3">
                            <div class="card org-card border-top-0" (click)="organisation['deleted_on']==null && select(j)"
                            [ngStyle]="{'cursor':organisation['deleted_on']==null ? 'pointer' : 'default' }">
                                <div class="card-header org-card-header m-0" [ngStyle]="{'border-color': organisation['partner_id'] === '0' ? '#b3463f !important' : 'red !important'}">
                                    <div class="row align-items-center" style="position: relative;">
                                        <div class="orgName height30 alignCenter">
                                            <h5 class="card-title m-0 text-truncate">{{organisation['name']}}</h5>
                                        </div>
                                    </div>
                                </div>
                            <div class="card-body org-card-body" style="min-height: 257px;">
                                    <ul class="list-unstyled flex-column justify-content-between font14">
                                        <li *ngIf="organisation['suspended_on']!= null"><b>{{admin_data.suspendedOn}}</b> {{(organisation['suspended_on']  * 1000 | date: "EEE, MMM d, y") }}</li>
                                        <li *ngIf="organisation['suspended_on']!= null"><b>{{admin_data.suspendedByUser}}</b> {{(organisation['suspended_by_first_name'] != null &&  organisation['suspended_by_last_name'] != null) ? organisation['suspended_by_first_name'] + " " + organisation['suspended_by_last_name'] : ''}}</li>
                                        <li *ngIf="organisation['suspended_on']!= null"><b>{{admin_data.suspendedByEmail}}</b> {{organisation['suspended_by_email']}}</li>
                                        <li *ngIf="organisation['deleted_on']!= null"><hr></li>
                                        <li *ngIf="organisation['deleted_on']!= null"><b>{{admin_data.deletedOn}}</b> {{(organisation['deleted_on']  * 1000 | date: "EEE, MMM d, y") }}</li>
                                        <li *ngIf="organisation['deleted_on']!= null"><b>{{admin_data.deletedByUser}}</b> {{(organisation['deleted_by_first_name'] != null &&  organisation['deleted_by_last_name'] != null) ? organisation['deleted_by_first_name'] + " " + organisation['deleted_by_last_name'] : ''}}</li>
                                        <li *ngIf="organisation['deleted_on']!= null"><b>{{admin_data.deletedByEmail}}</b> {{organisation['deleted_by_email']}}</li>
                                    </ul>
                                </div>
                                
                            

                            <div class="card-footer org-card-footer border-0">
                                    <div style="display: flex; justify-content: space-around; min-height: 35px;">
                                        <button *ngIf="organisation['deleted_on']==null" type="button" class="btn btn-outline-primary" (click)="changeActive(organisation,$event);$event.stopPropagation();">
                                        {{this.admin_data.reactivateBtn}}
                                        </button>
                                        <button
                                        *ngIf="organisation['backup_status'] == 'COMPLETED' && organisation['deleted_on']==null && checkThirtyDaysPassed(organisation['deleted_on'])"  
                                        type="button" class="btn btn-red ml-2" (click)="decommissionOrg(j);$event.stopPropagation();">
                                            {{this.admin_data.decommissionBtn}}
                                        </button>
                                        <button *ngIf="organisation['deleted_status'] == 'COMPLETED'"type="button" class="btn btn-red" (click)="deleteBackup(j);$event.stopPropagation();">
                                            {{this.admin_data.deleteBackupBtn}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </ng-template>
            <ul *ngIf="pages.length>1 && !(disableNext && disablePrev) && showPagination"
                class="pagination my-4 float-right">
                <li class="page-item mx-1" [class.disabled]="disablePrev">
                    <button class="page-link rounded" (click)="prependOffsetAndGetOrganisations()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                            <path
                                d="M8.50781 2.0275L3.54615 7L8.50781 11.9725L6.98031 13.5L0.480312 7L6.98031 0.5L8.50781 2.0275Z"
                                fill="#8E8FA6" />
                        </svg>
                    </button>
                </li>
                <ng-container *ngFor="let page of pages">
                    <li class="page-item mx-1" [ngClass]="{'active':page==currentPage}">
                        <a class="page-link rounded" (click)="setndex(page,$event)">{{page}}</a>
                    </li>
                </ng-container>
                <li class="page-item mx-1" [class.disabled]="disableNext">
                    <button class="page-link rounded" (click)="appendOffsetAndGetOrganisations()">
                        <svg xmlns="http://www.w3.org/2000/svg" width="9" height="14" viewBox="0 0 9 14" fill="none">
                            <path
                                d="M0.492188 11.9725L5.45385 7L0.492188 2.0275L2.01969 0.5L8.51969 7L2.01969 13.5L0.492188 11.9725Z"
                                fill="#8E8FA6" />
                        </svg>
                    </button>
                </li>
            </ul>
            <ng-container *ngIf="organisations">
                
                <div *ngIf="organisations" class="modal fade" id="mgAdminModal">
                    <div class="modal-dialog modal-dialog-centered" role="document">
                        <div class="modal-content">
                            <div class="modal-header">
                                <h5>{{this.admin_data.assignedAdmins}}</h5>
                                <svg-icon class="ml-auto" role="button" style="fill:#555555" src="../../../assets/svgs/close-icon-large.svg" (click)="closeAssignedAdminsModal()"></svg-icon>
                            </div>
                            <div class="modal-body overflow-auto text-wrap" >
                                <ng-container *ngIf="assignedAdmins.length > 0 ; else nodatafound">
                                    <span  *ngFor="let user of assignedAdmins">
                                        <span class="orgNames badge rounded-pill badge-secondary mr-2 font14 mb-2 px-2 ">{{user}}</span>
                                    </span>
                                </ng-container>
                                <ng-template #nodatafound>
                                    <div>{{this.admin_data.noDataFoundText}}</div>
                                </ng-template>
                            </div>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="organisations.length>0">
                    <app-delete-modal [delete_elem]="organisations[deleteIndex]['name']"
                        (onDelete)="deleteOrganisation($event)"></app-delete-modal>
                    <app-modal-confirmation [header_value]="modalHeader" [content_value]="modalContent" [modal_ok_value] = "modalOkText"
                    [org]="orgName"        (onDelete)="decommissionOrDeleteBackup($event)"></app-modal-confirmation>
                </ng-container>
            </ng-container>
        </div>
    </div>
</div>
