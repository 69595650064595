import { Injectable } from '@angular/core';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";

@Injectable({
  providedIn: 'root'
})
export class ErrorResponseService {


  constructor() { }

  getErrorResponseMessage(message?){


    let returnMessage;
    switch (message){
        
        case  "Invalid/Empty Header [AccessToken]":
            returnMessage = adminLabels.serverMessage1
        break;
        case  "[AccessToken] expired":
            returnMessage = adminLabels.serverMessage2
        break;
        case  "We're very sorry, it seems there is problem with your request or with our servers. We've been notified of the error and will correct it as soon as possible.":
            returnMessage = adminLabels.serverMessage3
        break;
        case  "User email is not verified":
            returnMessage = adminLabels.serverMessage4
        break;
        case  "Maintenance in progress. Please contact us via support@edcast.com ":
            returnMessage = adminLabels.serverMessage5
        break;
        case  "Invalid/Empty Parameter [token]":
            returnMessage = adminLabels.serverMessage6
        break;
        case  "You have reached the maximum limit to create steps":
            returnMessage = adminLabels.serverMessage7
        break;
        case  "Invalid/Empty Parameter [account unlock token]":
            returnMessage = adminLabels.serverMessage8
        break;
        case  "The support for this Admin API is not avalible. We have depricated this. We will remove in future releases.":
            returnMessage = adminLabels.serverMessage9
        break;
        case  "[Authorization] header expired":
            returnMessage = adminLabels.serverMessage10
        break;
        case  "Required parameter/s missing":
            returnMessage = adminLabels.serverMessage11
        break;
        case  "Invalid login credentials. Your account will be locked after multiple unsuccessful attempts.":
            returnMessage = adminLabels.serverMessage12
        break;
        case  "Invalid or expired OTP":
            returnMessage = adminLabels.serverMessage13
        break;
        case  "Uploaded file is not a valid image. (Only JPG and PNG files are allowed)":
            returnMessage = adminLabels.serverMessage14
        break;
        case  "Session expired":
            returnMessage = adminLabels.serverMessage15
        break;
        case  "User ID invalid. Please try again":
            returnMessage = adminLabels.serverMessage16
        break;
        case  "Email ID incorrect. Please try again":
            returnMessage = adminLabels.serverMessage17
        break;
        case  "User Role invalid. Please try again":
            returnMessage = adminLabels.serverMessage18
        break;
        case  "Invalid Parameter [task_type]":
            returnMessage = adminLabels.serverMessage19
        break;
        case  "Invalid/Empty Parameter [application_id]":
            returnMessage = adminLabels.serverMessage20
        break;
        case  "Invalid JSON":
            returnMessage = adminLabels.serverMessage21
        break;
        case  "Invalid/Empty Parameter [organization_id]":
            returnMessage = adminLabels.serverMessage22
        break;
        case  "Invalid/Empty Parameter [parent_id]":
            returnMessage = adminLabels.serverMessage23
        break;
        case  "Invalid/Empty Parameter [category_id]":
            returnMessage = adminLabels.serverMessage24
        break;
        case  "Invalid/Empty Parameters [from_category_id/to_category_id]":
            returnMessage = adminLabels.serverMessage25
        break;
        case  "Parameter required for Super Admin [organization_id]":
            returnMessage = adminLabels.serverMessage26
        break;
        case  "Image Data content should be base64 encoded":
            returnMessage = adminLabels.serverMessage27
        break;
        case  "Invalid Domain ID":
            returnMessage = adminLabels.serverMessage28
        break;
        case  "File type not supported":
            returnMessage = adminLabels.serverMessage29
        break;
        case  "Invalid parameter [file_id]":
            returnMessage = adminLabels.serverMessage30
        break;
        case  "Invalid/Empty Parameter [tour_id]":
            returnMessage = adminLabels.serverMessage31
        break;
        case  "Invalid/Empty Parameter [step_id]":
            returnMessage = adminLabels.serverMessage32
        break;
        case  "Invalid/Empty Header [GoogleToken]":
            returnMessage = adminLabels.serverMessage33
        break;
        case  "Invalid Team URL":
            returnMessage = adminLabels.serverMessage34
        break;
        case  "Invalid/Empty Header [EdcastToken]":
            returnMessage = adminLabels.serverMessage35
        break;
        case  "File size should be less than 5MB":
            returnMessage = adminLabels.serverMessage36
        break;
        case  "Invalid URL":
            returnMessage = adminLabels.serverMessage37
        break;
        case  "Your password must be between 8 and 15 characters, including at least one uppercase letter, one lowercase letter, one numeric value and one special character.":
            returnMessage = adminLabels.serverMessage38
        break;
        case  "The [password] field needs to be [6] or longer in length":
            returnMessage = adminLabels.passwordShort
        break;
        case  "The [password] field needs to be [20] or shorter in length":
            returnMessage = adminLabels.passwordLong
        break;
        case  "Invalid Password":
            returnMessage = adminLabels.serverMessage39
        break;
        case  "Invalid/Empty Parameter [country_id]":
            returnMessage = adminLabels.serverMessage40
        break;
        case  "Invalid/Empty Parameter [city_id]":
            returnMessage = adminLabels.serverMessage41
        break;
        case  "Invalid parameter [language]":
            returnMessage = adminLabels.serverMessage42
        break;
        case  "Authentication ID is invalid":
            returnMessage = adminLabels.serverMessage43
        break;
        case  "Invalid parameter [file_id]":
            returnMessage = adminLabels.serverMessage44
        break;
        case  "Invalid URL ID":
            returnMessage = adminLabels.serverMessage45
        break;
        case  "Invalid/Empty/Expired Header [Authorization]":
            returnMessage = adminLabels.serverMessage46
        break;
        case  "Invalid/Empty Header [AppKey]":
            returnMessage = adminLabels.serverMessage47
        break;
        case  "Invalid/Empty Header [RefreshToken]":
            returnMessage = adminLabels.serverMessage48
        break;
        case  "Invalid Role ID":
            returnMessage = adminLabels.serverMessage49
        break;
        case  "Invalid Segment ID":
            returnMessage = adminLabels.serverMessage50
        break;
        case  "Invalid Domain Name":
            returnMessage = adminLabels.serverMessage51
        break;
        case  "Duplicate segment data":
            returnMessage = adminLabels.serverMessage52
        break;
        case  "Invalid subscription ID":
            returnMessage = adminLabels.serverMessage53
        break;
        case  "Invalid organization role ID":
            returnMessage = adminLabels.serverMessage54
        break;
        case  "Invalid task list ID":
            returnMessage = adminLabels.serverMessage55
        break;
        case  "The task list has been unpublished":
            returnMessage = adminLabels.serverMessage56
        break;
        case  "The task list has been published":
            returnMessage = adminLabels.serverMessage57
        break;
        case  "2044":
            returnMessage = adminLabels.serverMessage58
        break;
        case  "Invalid Folder ID":
            returnMessage = adminLabels.serverMessage59
        break;
        case  "2045":
            returnMessage = adminLabels.serverMessage60
        break;
        case  "Invalid CONTENT ID":
            returnMessage = adminLabels.serverMessage61
        break;
        case  "Invalid parameter [external_user_id]":
            returnMessage = adminLabels.serverMessage62
        break;
        case  "Invalid step language data. Please check step title and description for any invalid or junk characters":
            returnMessage = adminLabels.serverMessage63
        break;
        case  "Invalid Job ID":
            returnMessage = adminLabels.serverMessage64
        break;
        case  "Action not allowed on locked user account":
            returnMessage = adminLabels.serverMessage65
        break;
        case  "Invalid/Empty parameter [segments]":
            returnMessage = adminLabels.serverMessage66
        break;
        case  "Invalid/Empty parameter [group_id]":
            returnMessage = adminLabels.serverMessage67
        break;
        case  "Invalid/Empty parameter [sso_id]":
            returnMessage = adminLabels.serverMessage68
        break;
        case  "Invalid URL provided":
            returnMessage = adminLabels.serverMessage69
        break;
        case  "Invalid/Empty Parameter [group_id]":
            returnMessage = adminLabels.serverMessage70
        break;
        case  "step_audio or step_audio_text fields are required":
            returnMessage = adminLabels.serverMessage71
        break;
        case  "Data integrity failed":
            returnMessage = adminLabels.serverMessage72
        break;
        case  "An old and new password can not be the same.":
            returnMessage = adminLabels.serverMessage73
        break;
        case  "action field are required":
            returnMessage = adminLabels.serverMessage74
        break;
        case  "Invalid/Empty Parameter [id]":
            returnMessage = adminLabels.serverMessage75
        break;
        case  "Subcategory cannot be created inside Category if a Guide is already created.":
            returnMessage = adminLabels.serverMessage76
        break;
        case  "If a user is already a Super Admin, you cannot change the User Role.":
            returnMessage = adminLabels.serverMessage77
        break;
        case  "This action is not allowed on a published guide":
            returnMessage = adminLabels.serverMessage78
        break;
        case  "Locked guide cannot be published.":
            returnMessage = adminLabels.serverMessage79
        break;
        case  "This action is not allowed on published category. Please unpublish it.":
            returnMessage = adminLabels.serverMessage80
        break;
        case  "Application must belong to the given organization":
            returnMessage = adminLabels.serverMessage81
        break;
        case  "You are not authorised to perform this action":
            returnMessage = adminLabels.serverMessage82
        break;
        case  "Action is not allowed for this Subcategory.":
            returnMessage = adminLabels.serverMessage83
        break;
        case  "Guide cannot be created inside Category if subcategory already created":
            returnMessage = adminLabels.serverMessage84
        break;
        case  "To create step/s, you need to unlock this guide":
            returnMessage = adminLabels.serverMessage85
        break;
        case  "To edit step/s, you need to unlock this guide":
            returnMessage = adminLabels.serverMessage86
        break;
        case  "To delete step/s, you need to unlock this guide":
            returnMessage = adminLabels.serverMessage87
        break;
        case  "To copy and paste step/s, you need to unlock this guide":
            returnMessage = adminLabels.serverMessage88
        break;
        case  "Action is not allowed for this tasklist.":
            returnMessage = adminLabels.serverMessage89
        break;
        case  "Action is not allowed on a published tasklist.":
            returnMessage = adminLabels.serverMessage90
        break;
        case  "Action is not allowed for this folder.":
            returnMessage = adminLabels.serverMessage91
        break;
        case  "This is already a draft guide, we can not create a new one":
            returnMessage = adminLabels.serverMessage92
        break;
        case  "The draft guide can not be created if guide is not published on highest environment.":
            returnMessage = adminLabels.serverMessage93
        break;
        case  "This guide already have a draft, we can not create a new draft for this.":
            returnMessage = adminLabels.serverMessage94
        break;
        case  "Invalid values of parameter [envs]":
            returnMessage = adminLabels.serverMessage95
        break;
        case  "You have reached the allowed limit for Guide ids":
            returnMessage = adminLabels.serverMessage96
        break;
        case  "Invalid values of parameter [Keywords]":
            returnMessage = adminLabels.serverMessage97
        break;
        case  "Action not allowed for tag step.":
            returnMessage = adminLabels.serverMessage98
        break;
        case  "steps cannot be paste in same tour":
            returnMessage = adminLabels.serverMessage100
        break;
        case  "Guide will be available for export soon. To check the status go to [Task Status] under account menu.":
            returnMessage = adminLabels.serverMessage101
        break;
        case  "Upload started. Please do not refresh or close the browser until upload is completed. You can check the import status once upload is completed":
            returnMessage = adminLabels.serverMessage102
        break;
        case  "User already exists":
            returnMessage = adminLabels.serverMessage103
        break;
        case  "Email ID is already verified":
            returnMessage = adminLabels.serverMessage104
        break;
        case  "This URL is conflicting with other application configuration, please try to add any other URL. For help, please contact support@edcast.com":
            returnMessage = adminLabels.serverMessage105
        break;
        case  "Category must belong to the same application":
            returnMessage = adminLabels.serverMessage106
        break;
        case  "Domain already exists":
            returnMessage = adminLabels.serverMessage107
        break;
        case  "You have reached the maximum limit to create guides":
            returnMessage = adminLabels.serverMessage108
        break;
        case  "Step ID must belong to the same guide":
            returnMessage = adminLabels.serverMessage109
        break;
        case  "This Organization is suspended. Please contact your Administrator.":
            returnMessage = adminLabels.serverMessage110
        break;
        case  "Guide ID/s must belong to the same category":
            returnMessage = adminLabels.serverMessage111
        break;
        case  "Guide already exists with the given pair of Object and Field name":
            returnMessage = adminLabels.serverMessage112
        break;
        case  "User suspended":
            returnMessage = adminLabels.serverMessage113
        break;
        case  "User already verified":
            returnMessage = adminLabels.serverMessage114
        break;
        case  "This feature is disabled on HPE":
            returnMessage = adminLabels.serverMessage115
        break;
        case  "OTP has been sent successfully to your email address":
            returnMessage = adminLabels.serverMessage116
        break;
        case  "Role already exists":
            returnMessage = adminLabels.serverMessage117
        break;
        case  "Country already exists":
            returnMessage = adminLabels.serverMessage118
        break;
        case  "City already exists":
            returnMessage = adminLabels.serverMessage119
        break;
        case  "Given guide/s already added in the queue for Export":
            returnMessage = adminLabels.serverMessage120
        break;
        case  "Category unpublished":
            returnMessage = adminLabels.serverMessage121
        break;
        case  "Category published":
            returnMessage = adminLabels.serverMessage122
        break;
        case  "Your organization is not verified. For help, please contact support@edcast.com":
            returnMessage = adminLabels.serverMessage123
        break;
        case  "JSON file is not available for this organization.":
            returnMessage = adminLabels.serverMessage124
        break;
        case  "API credentials are wrong or does not exist.":
            returnMessage = adminLabels.serverMessage125
        break;
        case  "JSON file is not available for this guide.":
            returnMessage = adminLabels.serverMessage126
        break;
        case  "No step available. Create at least one step to publish the guide.":
            returnMessage = adminLabels.serverMessage127
        break;
        case  "Please enter valid work email address.":
            returnMessage = adminLabels.serverMessage128
        break;
        case  "Default application.":
            returnMessage = adminLabels.serverMessage129
        break;
        case  "Default role.":
            returnMessage = adminLabels.serverMessage130
        break;
        case  "API key is used for SSO configuration.":
            returnMessage = adminLabels.serverMessage131
        break;
        case  "This request cannot be processed. Please contact us at contact support@edcast.com to enable this request.":
            returnMessage = adminLabels.serverMessage132
        break;
        case  "You have reached the maximum limit to create applications":
            returnMessage = adminLabels.serverMessage133
        break;
        case  "This feature is disabled":
            returnMessage = adminLabels.serverMessage134
        break;
        case  "The timestamp provided is invalid. Please provide a valid timestamp.":
            returnMessage = adminLabels.serverMessage135
        break;
        case  "You have reached the maximum limit to create users.":
            returnMessage = adminLabels.serverMessage136
        break;
        case  "Invalid/Expired Subscription.":
            returnMessage = adminLabels.serverMessage137
        break;
        case  "The scim ID provided is invalid.":
            returnMessage = adminLabels.serverMessage138
        break;
        case  "The filter provided is not supported.":
            returnMessage = adminLabels.serverMessage139
        break;
        case  "The specified resource is not available.":
            returnMessage = adminLabels.serverMessage140
        break;
        case  "Invalid SCIM schema.":
            returnMessage = adminLabels.serverMessage141
        break;
        case  "Invalid parameter.":
            returnMessage = adminLabels.serverMessage142
        break;
        case  "The required segment(s) are missing.":
            returnMessage = adminLabels.serverMessage143
        break;
        case  "This task is already completed.":
            returnMessage = adminLabels.serverMessage144
        break;
        case  "This user account is already unlocked.":
            returnMessage = adminLabels.serverMessage145
        break;
        case  "Your account has been temporarily locked due to excessive login failures. We have sent you an email with steps to unlock it. For more assistance, please contact us at support@edcast.com.":
            returnMessage = adminLabels.serverMessage146
        break;
        case  "Invalid data in provided JSON":
            returnMessage = adminLabels.serverMessage147
        break;
        case  "SSO is not enabled for this organization. Please contact us at contact support@edcast.com for more information.":
            returnMessage = adminLabels.serverMessage148
        break;
        case  "The audio is not created":
            returnMessage = adminLabels.serverMessage149
        break;
        case  "Excel File of guide list will be available soon.":
            returnMessage = adminLabels.serverMessage150
        break;
        case  "Tours not exist in given organization":
            returnMessage = adminLabels.serverMessage151
        break;
        case  "Your previous action is in progress, once it is completed you can perform next action.":
            returnMessage = adminLabels.serverMessage152
        break;
        case  "Tours already exist in given category":
            returnMessage = adminLabels.serverMessage153
        break;
        case  "The file(s) could not be imported into the application/category specified.":
            returnMessage = adminLabels.serverMessage154
        break;
        case  "Media file themes are not enabled for this organization. Please contact us at support@edcast.com for more information.":
            returnMessage = adminLabels.serverMessage155
        break;
        case  "Invalid destination for Guide Import":
            returnMessage = adminLabels.serverMessage156
        break;
        case  "Users not exist in given organization":
            returnMessage = adminLabels.serverMessage157
        break;
        case  "Excel File of user list will be available soon.":
            returnMessage = adminLabels.serverMessage158
        break;
        case  "Redis cache is disabled":
            returnMessage = adminLabels.serverMessage159
        break;
        case  "You can pin up to 5 guides":
            returnMessage = adminLabels.serverMessage160
        break;
        case  "Guide is already pinned":
            returnMessage = adminLabels.serverMessage161
        break;
        case  "Invalid/Empty parameter [mobile_app_id]":
            returnMessage = adminLabels.serverMessage162
        break;
        case  "JSON file is not available for this application.":
            returnMessage = adminLabels.serverMessage163
        break;
        case  "Bundle/Package id already exists.":
            returnMessage = adminLabels.serverMessage164
        break;
        case  "Segment with this name already exist.":
            returnMessage = adminLabels.serverMessage165
        break;
        case  "A new password can not be the same as the old 5 password.":
            returnMessage = adminLabels.serverMessage166
        break;
        case  "The [title] field may only contain alpha characters and spaces":
            returnMessage = adminLabels.serverMessage167
        break;
        case "This action is not allowed because job already in progress":
            returnMessage = adminLabels.playerPackageError;
        case "Guides cannot be paste in same category":
            returnMessage = adminLabels.pasteErrorMessage;
        break;
        case "This action is not allowed because json processing is not completed":
            returnMessage = adminLabels.jsonProcessingIncomplete;
        break;
        case "The content creation is not allowed for Mirror Application.":
            returnMessage = adminLabels.contentCreationNotAllowed;
        break;
        case "The [first_name] should not contain any special characrets.":
            returnMessage = adminLabels.firstNameError;
        break;
        case "The [last_name] should not contain any special characrets.":
            returnMessage = adminLabels.lastNameError;
        break;
        case "One or more guides already exist in another folder of this task list":
            returnMessage = adminLabels.oneOrMoreGuidesPresent;
        break;
        case "Guides are not exist for export for the selected apps.":
            returnMessage = adminLabels.guidesDoNotExist;
        break;
        default:
                returnMessage = adminLabels.serverMessageDefault
        break;
    }
    return returnMessage;
  }

    getErrorResponseMessageFromCode(code) {
        let returnMessage;
        switch (code) {
            case 3003:
                returnMessage = adminLabels.serverMessage103
                break;
            case 3071:
                returnMessage = adminLabels.numOfPartnerAdminExceeded;
                break;
            case 1005:
                returnMessage = adminLabels.reportNotAvailable
        }
        return returnMessage;
    }

    getErrorResponse(error) {
        let responseFromCode = this.getErrorResponseMessageFromCode(error['code']);
        if (responseFromCode) return responseFromCode;
        let responseFromMsg = this.getErrorResponseMessage(error['message'][0]);
        return responseFromMsg;
    }
}
