import { Component } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, Subject, takeUntil } from 'rxjs';
import { GuideInventoryService } from 'src/app/common/services/guide-inventory.service';
import { UserService } from 'src/app/common/services/user.service';
import adminLabels from "../../constants/ApplicationStrings/trans_utils";
import { BreadcrumbComponent } from 'src/app/breadcrumb/breadcrumb.component';
import { OrganisationService } from 'src/app/common/services/organisation.service';
declare var $;

@Component({
    selector: 'app-guide-detail',
    templateUrl: './guide-detail.component.html',
    styleUrl: './guide-detail.component.scss'
})
export class GuideDetailComponent {

    guideDetails = {};
    curr_org_id;
    breadcrumb;
    admin_data = adminLabels;
    languageArray = [];
    selectedLanguage = ""
    private destroy = new Subject<void>();
    constructor(
        public guideInventoryService: GuideInventoryService,
        private route: ActivatedRoute,
        public userService: UserService,
        private router: Router,
        private organisationService: OrganisationService
    ) {
        this.userService.getUserData();

        this.route.paramMap.pipe(takeUntil(this.destroy))
            .subscribe((params) => {
                if (params['params']['org_id']) {
                    if (params['params']['org_id'] !== undefined && params['params']['org_id'] !== '000') {
                        this.curr_org_id = parseInt(params['params']['org_id']);
                        this.userService.organization_id = this.curr_org_id;
                    }
                    if (params['params']['category_id']) {
                        console.log(params['params']['category_id']);

                        this.guideDetails['category_id'] = params['params']['category_id'];
                        this.guideDetails['tour_id'] = params['params']['guide_id'];
                        this.getGuideDetails();
                    }
                }
            });
        this.userService.activeTab = 'guide_inventory';

    }

    ngOnInit() {
        $('.tooltip').remove();
        this.organisationService.getCDNData();
    }


    ngOnDestroy() {
        this.destroy.unsubscribe();
    }

    getGuideDetails() {
        this.guideInventoryService.getGuideDetail(this.guideDetails['category_id'], this.guideDetails['tour_id']).subscribe((response) => {
            // console.log(response);
            this.guideDetails = response['data']['tour'];
            console.log(this.guideDetails);

            this.populateLanguageArray();
            // this.router.events
            // .pipe(filter((event) => event instanceof NavigationEnd))
            // .subscribe(() => {
            //     console.log(this.guideDetails);
            this.userService.setBreadcrumb([
                {
                    name: this.admin_data.guideInventory,
                    route: ['/organization', this.curr_org_id, 'guide_explorer']
                },
                {
                    name: this.guideDetails['tour_title'],
                    route: null,
                },
            ]);
            this.breadcrumb = this.userService.breadCrumbs;
            // });
        })
    }

    populateLanguageArray() {
        const organization = JSON.parse(localStorage.getItem('organization'));
        // console.log(adminSettings); 
        const adminSettings = JSON.parse(organization['admin_settings'])
        console.log(adminSettings);
        const language_settings = adminSettings['language_settings'];
        if (language_settings) {
            this.languageArray.push({
                value: language_settings['default']['language'],
                name: language_settings['default']['name']
            })
            if(language_settings['translations']){
                language_settings['translations'].forEach((translation) => {
                    this.languageArray.push({
                        value: translation['language'],
                        name: translation['name']
                    })
                })
            }
        } else {
            this.languageArray.push({
                name: "English (United States)",
                value: "en-US"
            })
        }
        this.selectedLanguage = "en-IN";
    }

    setActions(event) {
        this.selectedLanguage = event
    }

    getGuideTitle() {
        if (this.selectedLanguage.startsWith("en")) {
            return this.guideDetails['tour_title']
        }
        if (this.guideDetails && this.guideDetails['language_data']) {
            let languageData = JSON.parse(this.guideDetails['language_data']);
            console.log(languageData);
            return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['tour_title'] ? languageData[this.selectedLanguage]['tour_title'] : ""
        }
        return "";
    }

    getGuideDescription() {
        if (this.selectedLanguage.startsWith("en")) {
            return this.guideDetails['tour_description']
        }
        if (this.guideDetails && this.guideDetails['language_data']) {
            let languageData = JSON.parse(this.guideDetails['language_data']);
            console.log(languageData);
            return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['tour_description'] ? languageData[this.selectedLanguage]['tour_description'] : ""
        }
        return "";

    }

    getStepTitle(stepNumber) {

        if (this.guideDetails && this.guideDetails['steps']) {
            let step = this.guideDetails['steps'][stepNumber];
            if (this.selectedLanguage.startsWith("en")) {
                return step['step_title']
            }
            let languageData = JSON.parse(step['language_data']);
            console.log(languageData);
            return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['step_title'] ? languageData[this.selectedLanguage]['step_title'] : ""

        }
        return "";
    }

    getStepDescription(stepNumber) {
        if (this.guideDetails && this.guideDetails['steps']) {
            let step = this.guideDetails['steps'][stepNumber];
            if (this.selectedLanguage.startsWith("en")) {
                return step['step_description']
            }
            let languageData = JSON.parse(step['language_data']);
            console.log(languageData);
            return languageData[this.selectedLanguage] && languageData[this.selectedLanguage]['step_description'] ? languageData[this.selectedLanguage]['step_description'] : ""

        }
        return "";
    }
}
